<template>
  <div class="suggestion">
    <!-- 背景图 -->
    <div class="img-back"></div>
    <div class="content">
      <div class="suggestion-box">
        <div class="header">
          <div class="header-img">
            <img src="@/static/finance/34.png" alt />
          </div>
          <div class="header-title">请留下您的宝贵意见和建议</div>
        </div>

        <div class="con">
          <div class="left">
            <span>*</span>
            反馈内容
          </div>
          <div class="right">
            <el-input
              type="textarea"
              :rows="5"
              placeholder
              v-model="content"
            ></el-input>
          </div>
        </div>

        <div class="tel">
          <div class="left">
            <span>*</span>
            联系电话
          </div>
          <div class="right">
            <el-input type="input" placeholder v-model="mobile"></el-input>
          </div>
        </div>

        <div class="submit" @click="addComplaint">提交反馈</div>
      </div>
    </div>
  </div>
</template>

<script>
import { addComplaint } from "@/api/index.js";
export default {
  data() {
    return {
      content: "",
      mobile: ""
    };
  },
  methods: {
    // 提交
    addComplaint() {
      addComplaint({
        content: this.content,
        mobile: this.mobile
      }).then(res => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.content = "";
          this.mobile = "";
        } else {
          this.$message({
            message: res.msg,
            type: "warning"
          });
        }
      });
    }
  }
}; 
</script>

<style lang="less" scoped>
.suggestion {
  width: 100%;
  background-color: #fff;
  padding-bottom: 50px;
  box-sizing: border-box;
  .img-back {
    width: 100%;
    height: 400px;
    padding-top: 60px;
    box-sizing: border-box;
    background-image: url("/static/suggestion.png");
    background-size: cover;
    background-position: center;
    min-width: 1200px;
  }

  .content {
    width: 1200px;
    min-width: 1200px;
    height: 620px;
    background-color: #fbfbfb;
    position: relative;
    top: -60px;
    margin: 0 auto;
    padding: 100px 0px 0px 260px;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    .suggestion-box {
      width: 100%;
      height: auto;
      .header {
        width: 100%;
        height: 63px;
        display: flex;
        align-items: center;
        .header-img {
          width: 63px;
          height: 63px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .header-title {
          font-size: 21px;
          color: #0097e2;
          font-weight: bold;
        }
      }

      .con {
        display: flex;
        margin-top: 50px;
        .left {
          font-size: 16px;
          color: #333;
          margin-right: 20px;
          span {
            color: #0097e2;
          }
        }
        .right {
          width: 510px;
        }
      }
      .tel {
        display: flex;
        margin-top: 30px;
        .left {
          font-size: 16px;
          color: #333;
          margin-right: 20px;
          span {
            color: #0097e2;
          }
        }
        .right {
          width: 250px;
        }
      }

      .submit {
        width: 190px;
        height: 42px;
        background: rgba(0, 151, 226, 1);
        border-radius: 4px;
        font-size: 21px;
        color: #fff;
        line-height: 42px;
        text-align: center;
        margin-top: 66px;
        margin-left: 12px;
      }
    }
  }
}
</style>
